<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div>
      <table-system-settings-brands
        :systemSettingsBrand="systemSettingsBrand"
        @editForm="editForm"
      />
    </div>
    <modal-system-settings-brands-add
      @refresh="refresh"
      :organizations="organizations"
    />
    <modal-system-settings-brands-edit
      :id="id"
      :organizations="organizations"
      @refresh="refresh"
    />
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
  </div>
</template>

<script>
import tableSystemSettingsBrands from "@/views/component/Table/tableSystemSettingsBrands.vue";
import modalSystemSettingsBrandsAdd from "@/views/component/Modal/ModalSystemSettingsBrands/modalSystemSettingsBrandsAdd.vue";
import modalSystemSettingsBrandsEdit from "@/views/component/Modal/ModalSystemSettingsBrands/modalSystemSettingsBrandsEdit.vue";
export default {
  components: {
    tableSystemSettingsBrands,
    modalSystemSettingsBrandsAdd,
    modalSystemSettingsBrandsEdit,
  },
  data() {
    return {
      showPreloader: false,
      systemSettingsBrand: [],
      organizations: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Имя" },
        { key: "date_time", label: "Дата" },
      ],
      id: null,
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.openFilter();
    this.organization();
  },
  methods: {
    editForm(id) {
      this.id = id;
      setTimeout(() => {
        this.$bvModal.show("systemSettingsBrandsEdit");
      }, 0);
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.systemSettingsBrand = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.systemSettingsBrand.length
          );
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    sendToParent(tableData) {
      this.systemSettingsBrand = tableData;
      this.$store.commit(
        "pageData/setdataCount",
        this.systemSettingsBrand.length
      );
    },
    refresh() {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      this.showPreloader = true;
      this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
        this.showPreloader = false;
        this.systemSettingsBrand = res.data;
      });
    },
    organization() {
      this.$http.get("SystemSettings/brands/data").then((res) => {
        this.organizations = res.data;
      });
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (val) {
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.systemSettingsBrand = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.systemSettingsBrand.length
          );
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
